import revive_payload_client_4sVQNw7RlN from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/platform/customer-frontends/fpscasino/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/platform/customer-frontends/fpscasino/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/builds/platform/customer-frontends/fpscasino/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_hwlJ5PmHMh from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/builds/platform/customer-frontends/fpscasino/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import auth0_client_WDg96YyoDn from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/auth0.client.ts";
import click_outside_directive_84UULQwVnu from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/click-outside-directive.ts";
import error_handling_BTSyY7nAPP from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/error-handling.ts";
import gtmAnalytics_iBLTNKiJvi from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/gtmAnalytics.ts";
import maska_fpcrNrWUJx from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/maska.ts";
import setup_client_q4OWF4a4wm from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/setup.client.ts";
import vue_final_modal_Sd9bDDO4NH from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/vue-final-modal.ts";
import vue_notify_5ZKF81RpmH from "/builds/platform/customer-frontends/fpscasino/skeleton/plugins/vue-notify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_hwlJ5PmHMh,
  plugin_Jozdw60ZsE,
  auth0_client_WDg96YyoDn,
  click_outside_directive_84UULQwVnu,
  error_handling_BTSyY7nAPP,
  gtmAnalytics_iBLTNKiJvi,
  maska_fpcrNrWUJx,
  setup_client_q4OWF4a4wm,
  vue_final_modal_Sd9bDDO4NH,
  vue_notify_5ZKF81RpmH
]