import routerOptions0 from "/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/platform/customer-frontends/fpscasino/skeleton/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}